'use client';

import * as React from 'react';
import { Provider } from 'react-redux';
import type { ClientInferResponses } from '@ts-rest/core';
import { makeStore, type AppStore } from '@/lib/store';
import { setAuthProviders } from '@/lib/features/authProviders/authProvidersSlice';
import { setCoupons } from '@/lib/features/coupons/couponsSlice';
import { setCurrentUser } from '@/lib/features/currentUser/currentUserSlice';
import { setGenders } from '@/lib/features/genders/gendersSlice';
import { setImageGuideTypes } from '@/lib/features/imageGuideTypes/imageGuideTypesSlice';
import { setMembershipPeriods } from '@/lib/features/membershipPeriods/membershipPeriodsSlice';
import { setMembershipTypes } from '@/lib/features/membershipTypes/membershipTypesSlice';
import { setOrientationTypes } from '@/lib/features/orientationTypes/orientationTypesSlice';
import { setPrices } from '@/lib/features/prices/pricesSlice';
import { setPurchaseTypes } from '@/lib/features/purchaseTypes/purchaseTypesSlice';
import { setSizes } from '@/lib/features/sizes/sizesSlice';
import { setSizeTypes } from '@/lib/features/sizeTypes/sizeTypesSlice';
import { setStyles } from '@/lib/features/styles/stylesSlice';
import { setStyleTypes } from '@/lib/features/styleTypes/styleTypesSlice';
import { setToken } from '@/lib/features/token/tokenSlice';
import { setVerifyTypes } from '@/lib/features/verifyTypes/verifyTypesSlice';
import { setVersion } from '@/lib/features/version/versionSlice';
import { setIsReduxInitialized } from '@/lib/features/registry/registrySlice';
import type { Image, Size, Style, StyleType } from '@/types/entities';
import type { EnumImageGenerationStatus } from '../../../../../lib/database/lib/generated/enums';
import type { contract } from '../../../../../lib/backend/lib';
interface Props {
  children: React.ReactNode;
  initialData?: ClientInferResponses<typeof contract.getFrontendInitialData, 200>;
}
if (!process.env['NEXT_PUBLIC_PICTRIX_VERSION']) {
  throw new Error('Environment variable NEXT_PUBLIC_PICTRIX_VERSION is missing or empty');
}
export default function StoreProvider({
  children,
  initialData
}: Props): React.ReactElement {
  const storeRef = React.useRef<AppStore | null>(null);
  if (!storeRef.current) {
    // STEP 1 (SERVER-SIDE): Create the store instance the first time this renders
    storeRef.current = makeStore();

    // STEP 2 (SERVER-SIDE): Set initial state from props provided by server-side
    storeRef.current.dispatch(setCurrentUser(null));
    storeRef.current.dispatch(setAuthProviders(initialData?.status === 200 ? initialData.body.data.authProviders : null));
    storeRef.current.dispatch(setCoupons(initialData?.status === 200 ? initialData.body.data.coupons : null));
    storeRef.current.dispatch(setGenders(initialData?.status === 200 ? initialData.body.data.genders : null));
    storeRef.current.dispatch(setImageGuideTypes(initialData?.status === 200 ? initialData.body.data.imageGuideTypes : null));
    storeRef.current.dispatch(setMembershipPeriods(initialData?.status === 200 ? initialData.body.data.membershipPeriods : null));
    storeRef.current.dispatch(setMembershipTypes(initialData?.status === 200 ? initialData.body.data.membershipTypes : null));
    storeRef.current.dispatch(setOrientationTypes(initialData?.status === 200 ? initialData.body.data.orientationTypes : null));
    storeRef.current.dispatch(setPrices(initialData?.status === 200 ? initialData.body.data.prices : null));
    storeRef.current.dispatch(setPurchaseTypes(initialData?.status === 200 ? initialData.body.data.purchaseTypes : null));
    storeRef.current.dispatch(setSizes(initialData?.status === 200 ? initialData.body.data.sizes : null));
    storeRef.current.dispatch(setSizeTypes(initialData?.status === 200 ? initialData.body.data.sizeTypes : null));
    storeRef.current.dispatch(setStyles(initialData?.status === 200 ? initialData.body.data.styles : null));
    storeRef.current.dispatch(setStyleTypes(initialData?.status === 200 ? initialData.body.data.styleTypes : null));
    storeRef.current.dispatch(setVerifyTypes(initialData?.status === 200 ? initialData.body.data.verifyTypes : null));
    storeRef.current.dispatch(setVersion(process.env['NEXT_PUBLIC_PICTRIX_VERSION'] ?? null));
  }
  React.useEffect(() => {
    // STEP 3 (CLIENT-SIDE): Finalize setting of the initial state for things that need to run on the client side
    void (async () => {
      if (!storeRef.current) {
        return;
      }
      const token = await (await import('@/app/server-actions/cookies')).getCookie('auth-token');
      storeRef.current.dispatch(setToken(token ?? null));
      if (!token) {
        storeRef.current.dispatch(setCurrentUser(null));
        storeRef.current.dispatch(setIsReduxInitialized(true));
        return;
      }
      const {
        body,
        status
      } = await (await import('@/services/client-api-service')).clientApiService.getCurrentUser.query({
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      if (status !== 200) {
        console.error(body.message);
        storeRef.current.dispatch(setCurrentUser(null));
        storeRef.current.dispatch(setIsReduxInitialized(true));
        return;
      }
      storeRef.current?.dispatch(setCurrentUser(body.data));

      // Flag redux as being initialized for all the components waiting on it
      // This is especially important for the components waiting for the current user in order to
      // avoid a flash of unauthenticated content before the user gets set
      storeRef.current.dispatch(setIsReduxInitialized(true));
    })();
  }, []);
  React.useEffect(() => {
    // STEP 4 (CLIENT-SIDE): Load the generator settings
    void (async () => {
      if (!storeRef.current) {
        return;
      }
      const {
        setGeneratorSettingsGeneratingStatus,
        setGeneratorSettingsGenerationStartedAt,
        setGeneratorSettingsGpuProviderId,
        setGeneratorSettingsIsLoaded,
        setGeneratorSettingsIsPortrait,
        setGeneratorSettingsLastGeneratedImage,
        setGeneratorSettingsPrompt,
        setGeneratorSettingsSeed,
        setGeneratorSettingsShowImageGuide,
        setGeneratorSettingsSize,
        setGeneratorSettingsStyle,
        setGeneratorSettingsStyleType
      } = await import('@/lib/features/generatorSettings/generatorSettingsSlice');
      const generatingStatus = localStorage.getItem('generatorSettingsGeneratingStatus');
      const isPortrait = localStorage.getItem('generatorSettingsIsPortrait');
      const seed = localStorage.getItem('generatorSettingsSeed');
      const showImageGuide = localStorage.getItem('generatorSettingsShowImageGuide');
      const size = localStorage.getItem('generatorSettingsSize');
      const style = localStorage.getItem('generatorSettingsStyle');
      const styleType = localStorage.getItem('generatorSettingsStyleType');
      const lastGeneratedImage = localStorage.getItem('generatorSettingsLastGeneratedImage');
      const generationStartedAt = localStorage.getItem('generatorSettingsGenerationStartedAt');
      const gpuProviderId = localStorage.getItem('generatorSettingsGpuProviderId');
      storeRef.current.dispatch(setGeneratorSettingsGeneratingStatus((generatingStatus as keyof typeof EnumImageGenerationStatus) ?? null));
      storeRef.current.dispatch(setGeneratorSettingsIsPortrait(isPortrait === 'true'));
      storeRef.current.dispatch(setGeneratorSettingsPrompt(localStorage.getItem('generatorSettingsPrompt')));
      storeRef.current.dispatch(setGeneratorSettingsSeed(seed));
      storeRef.current.dispatch(setGeneratorSettingsShowImageGuide(showImageGuide === 'true'));
      storeRef.current.dispatch(setGeneratorSettingsSize(size ? (JSON.parse(size) as Size) : null));
      storeRef.current.dispatch(setGeneratorSettingsStyle(style ? (JSON.parse(style) as Style) : null));
      storeRef.current.dispatch(setGeneratorSettingsStyleType(styleType ? (JSON.parse(styleType) as StyleType) : null));
      storeRef.current.dispatch(setGeneratorSettingsLastGeneratedImage(lastGeneratedImage ? (JSON.parse(lastGeneratedImage) as Image) : null));
      storeRef.current.dispatch(setGeneratorSettingsGenerationStartedAt(generationStartedAt));
      storeRef.current.dispatch(setGeneratorSettingsGpuProviderId(gpuProviderId));
      storeRef.current.dispatch(setGeneratorSettingsIsLoaded(true));
    })();
  }, []);
  return <Provider store={storeRef.current} data-sentry-element="Provider" data-sentry-component="StoreProvider" data-sentry-source-file="StoreProvider.tsx">{children}</Provider>;
}