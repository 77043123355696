import type { CustomFlowbiteTheme } from 'flowbite-react'

export default {
  popover: {
    base: 'absolute z-20 shadow-xl shadow-shadow dark:shadow-xl dark:shadow-shadow-dark inline-block w-max max-w-[100vw] bg-white outline-none border border-border rounded-lg shadow-sm dark:border-border-dark dark:bg-neutral-800',
    content: 'z-10 max-h-96 overflow-y-auto rounded-[7px]',
    arrow: {
      base: 'absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border border-border dark:border-border-dark dark:bg-neutral-800 dark:mix-blend-color',
      placement: '-4px',
    },
  },
  tooltip: {
    target: 'w-fit',
    animation: 'transition-opacity',
    arrow: {
      base: 'absolute shadow-sm border-b border-r dark:border-none border-border z-10 h-2 w-2 rotate-45 bg-neutral-50 dark:bg-neutral-700',
      style: {
        dark: '',
        light: '',
        auto: 'bg-white dark:bg-neutral-700',
      },
      placement: '-4px',
    },
    base: 'absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm border border-border bg-neutral-50 text-primary-text dark:border-none dark:bg-neutral-700 dark:text-primary-text-dark',
    hidden: 'invisible opacity-0',
    style: {
      dark: '',
      light: '',
      auto: 'border border-border bg-white text-primary-text dark:border-none dark:bg-neutral-700 dark:text-primary-text-dark',
    },
    content: 'relative z-20',
  },
  dropdown: {
    arrowIcon: 'ml-2 h-4 w-4',
    content: 'py-1 focus:outline-none',
    floating: {
      animation: 'transition-opacity',
      arrow: {
        base: 'absolute z-10 h-2 w-2 rotate-45',
        style: {
          dark: 'bg-neutral-900 dark:bg-neutral-700',
          light: 'bg-white',
          auto: 'bg-white dark:bg-neutral-700',
        },
        placement: '-4px',
      },
      base: 'z-10 w-fit divide-y divide-neutral-100 rounded shadow focus:outline-none',
      content: 'py-1 text-sm text-secondary-text dark:text-secondary-text-dark',
      divider: 'my-1 h-px bg-neutral-100 dark:bg-neutral-600',
      header: 'block px-4 py-2 text-sm text-secondary-text dark:text-secondary-text-dark',
      hidden: 'invisible opacity-0',
      item: {
        container: '',
        base: 'flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm text-secondary-text hover:bg-neutral-100 focus:bg-neutral-100 focus:outline-none dark:text-secondary-text-dark dark:hover:bg-neutral-600 dark:hover:text-white dark:focus:bg-neutral-600 dark:focus:text-white',
        icon: 'mr-2 h-4 w-4',
      },
      style: {
        dark: 'bg-neutral-900 text-white dark:bg-neutral-700',
        light: 'border border-border bg-white text-primary-text',
        auto: 'border border-border bg-white text-primary-text dark:border-none dark:bg-neutral-700 dark:text-primary-text-dark',
      },
      target: 'w-fit',
    },
    inlineWrapper: 'flex items-center',
  },
} satisfies CustomFlowbiteTheme
